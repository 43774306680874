import { Box, Breadcrumbs, CircularProgress, Link } from "@mui/joy";
import ColorSchemeToggle from "components/ColorSchemeToogle";
import Header from "components/Header/Header";
import FirstSidebar, { menuItems } from "components/Sidebar/MainSideBar";
import SecondSidebar from "components/Sidebar/SecondSidebar";
import { useAuth } from "hooks/useAuth";
import { Outlet, useLocation } from "react-router-dom";
import { Home, ChevronRight } from "react-feather";
import { Link as RouterLink } from "react-router-dom";
import * as ROUTES from "routes";
import { mainCategories } from "pages/Dashboard/DashboardPage";

export function MainLayout() {
  const { loading, user } = useAuth();
  let location = useLocation();
  if (loading) {
    return (
      <CircularProgress
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    );
  }
  if (!user) return null;
  const showBreadcrumb =
    !location.pathname.startsWith(ROUTES.DRAFT) &&
    location.pathname !== ROUTES.DASHBOARD;
  //find matching route
  const foundRoutes = menuItems.find((route) =>
    route.href === ROUTES.DASHBOARD
      ? false
      : location.pathname.startsWith(route.href)
  );

  const firstRoute =
    foundRoutes?.href === ROUTES.DASHBOARD ? null : foundRoutes;

  const secondRoute = mainCategories.find(
    (route) => route.href === location.pathname
  );

  return (
    <Box sx={{ display: "flex", minHeight: "100dvh" }}>
      <Header />
      <FirstSidebar />
      <SecondSidebar />
      <Box
        component="main"
        className="MainContent"
        sx={(theme) => ({
          px: {
            xs: 2,
            md: 6,
          },
          pt: {
            xs: `calc(${theme.spacing(2)} + var(--Header-height))`,
            sm: `calc(${theme.spacing(2)} + var(--Header-height))`,
            md: 3,
          },
          pb: {
            xs: 2,
            sm: 2,
            md: 3,
          },
          flex: 1,
          display: "flex",
          flexDirection: "column",
          minWidth: 0,
         // height: "100dvh",
          gap: 1,
        })}
      >
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          {showBreadcrumb && (
            <Breadcrumbs
              size="sm"
              aria-label="breadcrumbs"
              separator={<ChevronRight className="feather" />}
              sx={{
                "--Breadcrumbs-gap": "1rem",
                "--Icon-fontSize": "16px",
                fontWeight: "lg",
                color: "neutral.400",
                px: 0,
              }}
            >
              <Link
                underline="none"
                color="neutral"
                fontSize="inherit"
                component={RouterLink}
                to={ROUTES.DASHBOARD}
                aria-label="Home"
              >
                <Home className="feather" />
              </Link>

              {firstRoute && (
                <Link
                  underline="hover"
                  color="neutral"
                  fontSize="inherit"
                  component={RouterLink}
                  to={firstRoute.href}
                  aria-label={firstRoute.title}
                >
                  {firstRoute.title}
                </Link>
              )}
              {secondRoute && (
                <Link
                  underline="hover"
                  color="neutral"
                  fontSize="inherit"
                  component={RouterLink}
                  to={secondRoute.href}
                  aria-label={secondRoute.title}
                >
                  {secondRoute.title}
                </Link>
              )}
            </Breadcrumbs>
          )}

          <ColorSchemeToggle
            sx={{ ml: "auto", display: { xs: "none", md: "inline-flex" } }}
          />
        </Box>
        <Outlet />
      </Box>
    </Box>
  );
}
