import { Pages } from "./types";

/**
 * Check if it is a JÜB pdf. It checks if the testString is in the extracted texts.
 * @param pages
 * @param testString - default is 'A hatóság a Jogügyletek Biztonságát Erősítő Adatszolgáltatási Keretrendszeren (JÜB) keresztül'
 * @returns boolean
 */
export const isItAJUBPdf = (
  pages: Pages,
  testString: string = "A hatóság a Jogügyletek Biztonságát Erősítő Adatszolgáltatási Keretrendszeren (JÜB) keresztül"
) => {
  let isJUB = false;
  pages.forEach((page) => {
    page.textsWithCoordines.forEach((item) => {
      if (item.str.indexOf(testString) !== -1) {
        isJUB = true;
      }
    });
  });

  return isJUB;
};

/**
 * Get texts in order from pages array.
 * @param pages
 * @returns string[]
 */
export const getTextsFromPagesInOrder = (pages: Pages) => {
  const texts: string[] = [];

  // sort texts by y coordinate, plus add them to texts array
  try {
    pages.forEach((page, index) => {
      page.textsWithCoordines.sort((a, b) => {
        if (a.y === b.y) {
          return a.x - b.x;
        }

        return b.y - a.y;
      });

      page.textsWithCoordines.forEach((item) => {
        // console.log(`${Number.parseInt(item.y.toString())} - ${item.str}`);

        texts.push(item.str.trim());
      });
    });
  } catch (error: any) {
    console.log("Error happened during processing pages: ", error);
  }

  return texts;
};

/**
 * Convert text to key. It converts the text to lowercase, removes accents, replaces spaces with underscores, and removes all non-alphanumeric characters.
 * @param str
 * @returns string
 */
export const convertStringToKey = (str: string) => {
  let key = str.toLowerCase().replace(" :", "");

  [
    { from: "á", to: "a" },
    { from: "é", to: "e" },
    { from: "í", to: "i" },
    { from: "ó", to: "o" },
    { from: "ö", to: "o" },
    { from: "ő", to: "o" },
    { from: "ú", to: "u" },
    { from: "ü", to: "u" },
    { from: "ű", to: "u" },
  ].forEach((map) => {
    key = key.replace(new RegExp(map.from, "g"), map.to);
  });

  key = key.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

  key = key.replace(/[^a-z0-9]/g, "_");

  return key;
};

/**
 * Remove keys from an object where the value is an empty string. It makes a deep search.
 * @param obj:any
 * @returns void
 */
export const DATA_removeEmptyStrings = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === "object") {
      DATA_removeEmptyStrings(obj[key]);
    } else if (obj[key] === "") {
      delete obj[key];
    }
  });
};

/**
 * Remove keys from an object where the value is an empty object. It makes a deep search.
 * @param obj:any
 * @returns void
 */
export const DATA_removeEmptyObjects = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === "object") {
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key];
      } else {
        DATA_removeEmptyObjects(obj[key]);
      }
    }
  });
};
