// PDF JS imports
//@ts-ignore
//import * as pdfjsLib from "pdfjs-dist/build/pdf.mjs";
//import * as pdfjsLib from 'pdfjs-dist/webpack.mjs';

// TYPE IMPORTS
import { Pages, LekertOkmany, LekertSzemelyAdatok } from "./types";

import {
  TextItem,
  TextMarkedContent,
  PDFDocumentProxy,
} from "pdfjs-dist/types/src//display/api";

// HELPER FUNCS
import {
  isItAJUBPdf,
  convertStringToKey,
  DATA_removeEmptyObjects,
  DATA_removeEmptyStrings,
  getTextsFromPagesInOrder,
} from "./helper-funcs";

/**
 * Load texts and coordinates to pages array. It generates a Pages object.
 * @param doc:PDFDocumentProxy
 * @returns Promise<Pages>
 */
const STEP1_loadContentToPages = (doc: PDFDocumentProxy) => {
  const numPages = doc.numPages;

  // PAGES - texts with coordinates, grouped by page
  const pages: Pages = [];

  const loadPage = function (pageNum: number) {
    return doc.getPage(pageNum).then(function (page: any) {
      return page
        .getTextContent()
        .then(function (content: any) {
          const pageObject: {
            textsWithCoordines: {
              x: number;
              y: number;
              str: string;
            }[];
          } = {
            textsWithCoordines: [],
          };

          pages.push(pageObject);

          content.items.forEach(function (item: TextItem | TextMarkedContent) {
            if (!("str" in item) || !item.str.trim()) {
              return;
            }

            pageObject.textsWithCoordines.push({
              x: item.transform[4],
              y: item.transform[5],
              str: item.str.trim(),
            });
          });

          // Release page resources.
          page.cleanup();
        })

        .catch((error: any) => {
          console.log(
            "Error happened during loading content from pdf: ",
            error.message
          );
        });
    });
  };

  let lastPromise = loadPage(1); // will be used to chain promises

  // Loading of the first page will wait on metadata and subsequent loadings
  // will wait on the previous pages.
  for (let i = 2; i <= numPages; i++) {
    lastPromise = lastPromise.then(loadPage.bind(null, i));
  }

  return lastPromise.then(function () {
    return pages;
  });
};

/**
 * Process Pages, extract the infos from the texts and and returns this data.
 * @param pages
 * @returns LekertSzemelyAdatok
 */
const STEP2_processPages = function (pages: Pages) {
  if (!isItAJUBPdf(pages)) {
    throw new Error("This is not a JÜB pdf! I did not find the test string!");
  }

  // DATA
  let currentDataType: "DATA" | "OKMANY" = "DATA";

  const data: LekertSzemelyAdatok = {
    szemelyre_vonatkozo_adatok: {
      szemelyi_azonositoja: "",
      viselt_neve: "",
      szuletesi_neve: "",
      anyja_neve: "",
      szuletesi_ideje: "",
      neme: "",
      szuletesi_hely: "",
    },
    okmanyra_vonatkozo_adatok: {
      okmany_tipus: "",
      okmanyazonosito: "",
    },
    szemelyadatok: {
      szemelyi_azonositoja: "",
      viselt_neve: "",
      szuletesi_neve: "",
      anyja_neve: "",
      szuletesi_ideje: "",
      neme: "",
      szuletesi_orszag_megnevezese: "",
      szuletesi_hely: "",
      allampolgarsaga: "",
      nyilvantartas_jogcime: "",
    },
    lakohely_adatai: {
      iranyitoszam: "",
      telepules_neve: "",
      kerulet: "",
      cim_tovabbi_adatai: "",
      cim_bejelentesenek_datuma: "",
    },
    tartozkodasi_hely_adatai: {
      iranyitoszam: "",
      telepules_neve: "",
      kerulet: "",
      cim_tovabbi_adatai: "",
      cim_bejelentesenek_datuma: "",
      cim_kijelentesenek_datuma: "",
    },
    okmanyok: new Array<LekertOkmany>(),
  };

  const okmanyTemplate: LekertOkmany = {
    okmanyadatok: {
      okmany_tipus: "",
      okmanyazonosito: "",
    },
    okmanyhoz_tartozo_szemely_termeszetes_szemelyazonosito_adatai: {
      viselt_neve: "",
      szuletesi_neve: "",
      anyja_neve: "",
      szuletesi_ideje: "",
      neme: "",
      szuletesi_orszag_megnevezese: "",
      szuletesi_hely: "",
      szuletesi_hely_megnevezesenek_magyar_megfeleloje: "",
      allampolgarsaga: "",
      okmany_ervenyesseg_datuma: "",
    },
    lakohely: {
      iranyitoszam: "",
      telepules_neve: "",
      kerulet: "",
      cim_tovabbi_adatai: "",
      cim_bejelentesenek_datuma: "",
    },
    tartozkodasihely: {
      iranyitoszam: "",
      telepules_neve: "",
      kerulet: "",
      cim_tovabbi_adatai: "",
      cim_bejelentesenek_datuma: "",
      cim_ervenyessegenek_datuma: "",
    },
    kiallitasara_vonatkozo_adatok: {
      kiallito_hatosag: "",
      kiallitas_datuma: "",
      okmany_allapota: "",
    },
  };
  let okmany: LekertOkmany = JSON.parse(JSON.stringify(okmanyTemplate));

  // we will add any data through this object
  let dataToFill: any = null;

  // GET TEXTS IN ORDER
  const texts: string[] = getTextsFromPagesInOrder(pages);

  // processing texts
  try {
    for (let i = 0; i < texts.length; i++) {
      let text = texts[i].trim();

      // end of data
      if (text.indexOf("adatszolgáltatás jogalapja") !== -1) {
        break;
      }

      // skip unnecessary data
      if (
        text.indexOf("Szolgáltatott adatok") !== -1 ||
        text.toLowerCase().indexOf("adatszolgáltatás") !== -1 ||
        text.indexOf("adatigénylő űrlap") !== -1 ||
        text.indexOf("Oldal:") !== -1 ||
        text.indexOf("Üzenet száma") !== -1 ||
        text.indexOf("Üzenet tartalma") !== -1
      ) {
        continue;
      }

      const key = convertStringToKey(text);

      // SWITCH TO OKMANY MODE
      if (key === "okmanyadatok") {
        okmany = JSON.parse(JSON.stringify(okmanyTemplate));

        data.okmanyok.push(okmany);
        currentDataType = "OKMANY";
      }

      if (currentDataType === "DATA") {
        if (
          key === "szemelyre_vonatkozo_adatok" ||
          key === "okmanyra_vonatkozo_adatok" ||
          key === "szemelyadatok" ||
          key === "lakohely_adatai" ||
          key === "tartozkodasi_hely_adatai"
        ) {
          dataToFill = data[key];
          continue;
        }
      } else {
        if (
          key === "okmanyadatok" ||
          key ===
            "okmanyhoz_tartozo_szemely_termeszetes_szemelyazonosito_adatai" ||
          key === "lakohely" ||
          key === "tartozkodasihely" ||
          key === "kiallitasara_vonatkozo_adatok"
        ) {
          dataToFill = okmany[key];
          continue;
        }
      }

      if (
        text.indexOf(":") !== -1 &&
        i < texts.length - 1 &&
        dataToFill !== null
      ) {
        // console.log(`${key}: ${texts[i + 1].trim()}`);

        if (texts[i + 1].trim() === "" || texts[i + 1].indexOf(":") !== -1) {
          continue;
        }

        if (typeof dataToFill[key] === "string" && dataToFill[key] !== "") {
          dataToFill[key + "2"] = texts[++i].trim();
        } else {
          dataToFill[key] = texts[++i].trim();
        }

        // console.log(`${key}: ${texts[++i].trim()}`);
      }
    }
  } catch (error: any) {
    console.log("Error happened during processing texts: ", error);
  }

  // REMOVE EMPTY KEYS
  try {
    DATA_removeEmptyStrings(data);
    DATA_removeEmptyObjects(data);
  } catch (error: any) {
    console.log("Error happened during removing empty properties: ", error);
  }

  return data;
};

/**
 * MAIN FUNCTION: Extract LekertSzemelyAdatok from a PDF
 * @param pdfPath: pdf url
 * @returns LekertSzemelyAdatok
 */
export const getLekertSzemelyAdatokFromPDF = async (
  pdfPath: string | Uint8Array
) => {
  let doc: PDFDocumentProxy;
  let pages: Pages;

  /** STEP 0: Open PDF file **/
  try {
    
    //@ts-ignore
    //const PDFJS = await import("pdfjs-dist/build/pdf.mjs");
    //console.log(window.pdfjsLib)
    //@ts-ignore
    doc = await window.pdfjsLib.getDocument(pdfPath).promise;
  } catch (error: any) {
    throw new Error("Could not open the file: " + JSON.stringify(error));
  }

  /** STEP 1: Load text content to Pages **/
  try {
    //@ts-ignore
    pages = await STEP1_loadContentToPages(doc);
  } catch (error: any) {
    throw new Error(
      "Couldn't load text content to Pages: " + JSON.stringify(error)
    );
  }

  /** STEP 2: Process Pages and return LekertSzemelyAdatok **/
  try {
    return STEP2_processPages(pages);
  } catch (error: any) {
    throw new Error(
      "Error happened during Pages processing: " + JSON.stringify(error)
    );
  }
};
