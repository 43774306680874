export const theme = {
  "colorSchemes": {
    "light": {
      "palette": {
        "primary": {
          "solidBg": "#037AEB",
          "solidActiveBg": "#022C65",
          "solidHoverBg": "#054597",
          "softColor": "#046CCE",
          "softHoverBg": "#A3C7EB",
          "outlinedColor": "#037AEB",
          "outlinedBorder": "#037AEB",
          "outlinedHoverBorder": "#037AEB",
          "outlinedHoverBg": "#E3F2FF",
          "outlinedActiveBg": "#9EC7E6",
          "plainColor": "#046CCE",
          "plainHoverBg": "#E3F2FF",
          "plainActiveBg": "#9EC7E6"
        },
        "info": {
          "solidBg": "#70C7E5",
          "solidActiveBg": "#43B9CA",
          "solidHoverBg": "#359FAE",
          "solidDisabledBg": "#B8E8EF",
          "softColor": "#0C8CB7",
          "softBg": "#DBF2FB",
          "softHoverBg": "#9FD1E1",
          "softActiveBg": "#53ABCA",
          "softDisabledColor": "#8DBCCC",
          "softDisabledBg": "#F0F8F9",
          "outlinedBorder": "#70C7E5",
          "outlinedHoverBorder": "#70C7E5",
          "outlinedColor": "#2DA8D1",
          "outlinedHoverBg": "#D5EEF6",
          "outlinedActiveBg": "#AEDEEE",
          "outlinedDisabledColor": "#D5EEF6",
          "outlinedDisabledBorder": "#D5EEF6",
          "plainColor": "#2DA8D1",
          "plainHoverBg": "#D5EEF6",
          "plainActiveBg": "#AEDEEE",
          "plainDisabledColor": "#D5EEF6"
        },
        "warning": {
          "solidHoverBg": "#AE6717",
          "solidBg": "#F58100",
          "softHoverBg": "#EBB880",
          "solidColor": "#FFFFFF",
          "solidActiveBg": "#5F3708",
          "softColor": "#C06403",
          "softActiveBg": "#FDAB4E",
          "outlinedBorder": "#F58100",
          "outlinedColor": "#914E01",
          "outlinedDisabledBorder": "#FFDEB7",
          "outlinedDisabledColor": "#FAD5B1",
          "outlinedActiveBg": "#F6B366",
          "outlinedHoverBg": "#FADDBA",
          "outlinedHoverBorder": "#F58100",
          "plainColor": "#914E01",
          "plainDisabledColor": "#ECB873",
          "plainActiveBg": "#E4B985",
          "plainHoverBg": "#FADDBA"
        },
        "success": {
          "solidBg": "#0EAF2E",
          "solidDisabledBg": "#AEDCB8",
          "softColor": "#08801F",
          "softActiveBg": "#11D236",
          "softHoverBg": "#90D49D",
          "outlinedBorder": "#0EAF2E",
          "outlinedHoverBorder": "#0EAF2E",
          "outlinedHoverBg": "#C2EECB",
          "outlinedActiveBg": "#63C576",
          "outlinedColor": "#0EAF2E",
          "plainColor": "#0EAF2E",
          "plainDisabledColor": "#B0E8BB",
          "plainHoverBg": "#C2EECB",
          "plainActiveBg": "#63C576"
        },
        "neutral": {
          "solidBg": "#576C77",
          "outlinedColor": "#576C77",
          "outlinedBorder": "#A3BEBE",
          "outlinedHoverBorder": "#576C77",
          "plainColor": "#576C77",
          "solidDisabledColor": "#AAB3C8",
          "solidDisabledBg": "#F7F7F7",
          "softBg": "#E7E7E7",
          "softDisabledBg": "#F3F3F3"
        },
        "danger": {
          "solidBg": "#CA180A",
          "softColor": "#CA180A",
          "outlinedColor": "#CA180A",
          "outlinedBorder": "#CA180A",
          "outlinedHoverBorder": "#CA180A",
          "outlinedHoverBg": "#FDDEDB",
          "outlinedActiveBg": "#E4A09A",
          "plainColor": "#CA180A",
          "plainHoverBg": "#FDDEDB",
          "plainActiveBg": "#E4A09A"
        },
        "background": {
          "body": 'var(--joy-palette-neutral-50)',
        },
      }
    },
    "dark": {
      "palette": {
        "warning": {
          "solidBg": "#F58100",
          "solidColor": "#FFFFFF",
          "solidHoverBg": "#8A5111",
          "solidActiveBg": "#5F3708"
        },
        "primary": {
          "solidBg": "#037AEB",
          "softColor": "#C3D7EF",
          "softDisabledColor": "#1E609D",
          "solidDisabledColor": "#7590B3",
          "solidDisabledBg": "#021C34",
          "solidHoverBg": "#034094",
          "plainColor": "#037AEB",
          "plainDisabledColor": "#03366D",
          "outlinedColor": "#719DCB",
          "outlinedBorder": "#037AEB"
        },
        "neutral": {
          "solidBg": "#576C77",
          "softHoverBg": "#2A3A41",
          "outlinedHoverBg": "#1A2428",
          "solidHoverBg": "#394549",
          "plainHoverBg": "#2D373B",
          "outlinedBorder": "#2B363C"
        },
        "danger": {
          "solidBg": "#CA180A",
          "outlinedBorder": "#951107",
          "outlinedDisabledBorder": "#3E0903"
        },
        "success": {
          "solidBg": "#0EAF2E"
        },
        "info": {
          "solidBg": "#35A7D0",
          "solidHoverBg": "#276C84",
          "solidActiveBg": "#114253",
          "solidDisabledColor": "#2A5361",
          "solidDisabledBg": "#0A222C",
          "softHoverBg": "#276C84",
          "softBg": "#1B3D46",
          "softActiveBg": "#35A7D0",
          "softDisabledBg": "#0A2025",
          "softColor": "#A9CFDB",
          "softDisabledColor": "#36495B",
          "outlinedColor": "#CBFAFF",
          "outlinedBorder": "#35A7D0",
          "outlinedHoverBg": "#16566D",
          "outlinedHoverBorder": "#35A7D0",
          "outlinedActiveBg": "#0C4155",
          "outlinedDisabledColor": "#0C4155",
          "outlinedDisabledBorder": "#052834",
          "plainDisabledColor": "#0C4155",
          "plainHoverBg": "#16566D",
          "plainColor": "#CBFAFF",
          "plainActiveBg": "#1B7291"
        },
        "background": {
          "body": 'var(--joy-palette-common-black)',
          "surface": 'var(--joy-palette-neutral-900)',
        },
      }
    }
  },
  fontFamily: {
    body: "Open Sans",
    display: "Open Sans",
    //display: "'Inter', var(--joy-fontFamily-fallback)",
    //body: "'Inter', var(--joy-fontFamily-fallback)"
  },
  components: {
    JoyTooltip: {
      styleOverrides: {
        // `ownerState` contains the component props and internal state
        root: () => ({
          zIndex: 10001,
        }),
      },
    },
  },
};
